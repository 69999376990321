import Stars from '../../../../../assets/icons/star.svg'
import styles from './StarGradation.module.scss'

const StarGradation = ({ totalStars }) => {
  return (
    <div className={ styles.starContainer }>
      { [ 1, 2, 3, 4, 5 ].map((item, index) =>
        <Stars className={ styles.star + (item <= totalStars ? ' ' + styles.activeStar : '') } key={ item }/>
      ) }
    </div>
  )
}

export default StarGradation
