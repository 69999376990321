import Link from "next/link"
import styles from './Card.module.scss'
import SaleBadge from "@/components/Home/PopularClubs/Card/SaleBadge/SaleBadge"
import LazyLoadImage from "@/layouts/LazyLoadImage/LazyLoadImage";

// address: "Москва, ул. Большая Черкизовская, дом 6"
// has_cards: false
// has_workouts: true
// img: "https://fitness.find-best.ru/static/web/images/club_photo_missing.jpg"
// is_action: true
// logo: "https://fitness.find-best.ru/media/fitness_club/1357738607_fitnes-klub-street-fitnes.png"
// slug: "street-fitnes"
// title: "Street Фитнес"
const options = {
  has_workouts: {
    title: 'Разовое посещение'
  },
  has_cards: {
    title: 'Абонементы'
  }
}
const Card = ({ item }) => {
  return (
    <div className={ styles.root }>
      <Link as={ '/club/' + item.slug } href={ '/club/[slug]' }>
        <a>
          <LazyLoadImage img={ <img src={ item.img } alt={ item.title }/> }/>
          <div className={ styles.container }>
            <div className={ styles.badges }>
              <div className={ styles.options }>
                { Object.keys(options).map(key => {
                  const optionItem = item[ key ]
                  const optionTitle = options[ key ].title
                  return !optionItem ? null :
                    <div className={ styles.option } key={ key + item.slug }>{ optionTitle }</div>
                }) }
              </div>
              <SaleBadge isSale={ item.is_action }/>
            </div>
            <div className={ styles.info }>
              <div className={ styles.title }>{ item.title }</div>
              <div className={ styles.address }>{ item.address }</div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default Card
