import styles from './SaleBadge.module.scss'

const SaleBadge = ({ isSale }) => {
  return (
    isSale ?
      <div className={ styles.isAction }>
        Акция
      </div> : null
  )
}

export default SaleBadge
