import styles from './SubscriptionAndCon.module.scss'
import { connect } from 'react-redux'
import ConsultationI from '../../../assets/icons/consultation.svg'
import Blog from './blog.svg'
import Btn from "@/gdeluLib/Btn/Btn"
import { openModal } from "@/store/modal/actions"

const SubscriptionAndCon = ({ openModal, modalNames }) => {
  const options = [
    {
      title: 'Подпишитесь на наш фитнес-блог',
      description: 'Полезные статьи и новости о фитнесе, без спама!',
      btnTitle: 'Подписаться',
      handler: openModal.bind(null, modalNames.blogSubscribe),
    },
    // {
    //   title: 'Заказать консультацию',
    //   description: 'Наш фитнес-консультант поможет вам выбрать лучший клуб для тренировок!',
    //   btnTitle: 'Заказать консультацию',
    //   handler: openModal.bind(null, modalNames.consultation),
    // }
  ]
  return (
    <div className={ styles.root + ' margin-v-1' }>
      { options.map((item, index) =>
        <div className={ styles.item } key={ item.title }>
          { !index ? <Blog/> : <ConsultationI/> }
          <div className={ styles.itemContainer }>
            <div className={ styles.title + ' title-h3' }>{ item.title }</div>
            <div className={ styles.description }>{ item.description }</div>
            <Btn styleBtn={ 'blue' } className={ styles.btn } onClick={ item.handler } title={ item.btnTitle }/>
          </div>
        </div>
      ) }
    </div>
  )
}

const mapStateToProps = state => ({
  modalNames: state.modal.modalNames
})

const mapDispatchToProps = dispatch => ({
  openModal: modalName => (dispatch(openModal(modalName)))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionAndCon)
