import { memo } from 'react'
import { connect } from 'react-redux'
import { changeFaqDropDown } from "../../../store/faq/actions"
import styles from './FAQ.module.scss'
import { withRouter } from "next/router"
import DropDown from "@/gdeluLib/DropDown/DropDown"

const FAQ = memo((props) => {
  const { faqList, router } = props
  const { providerAlias } = router.query
  return (
    <div className={ styles.root }>
      <h2 className={ styles.title + ' title-h1 text-center margin-v-2' }>FAQ</h2>
      <div>
        <DropDown items={ faqList } isMoreColumn={ false }
                  isProviderFaq={ providerAlias }
                  onUpdate={ props.changeFaqDropDown }/>
      </div>
    </div>
  )
})

const mapStateToProps = state => {
  return {
    faqList: state.faq.list,
    windowInnerWidth: state.window.size.windowInnerWidth,
  }
}

const mapDispatchToProps = dispatch => ({
  changeFaqDropDown: arr => dispatch(changeFaqDropDown(arr)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FAQ))
