import styles from './Card.module.scss'
import Link from "next/link"
import LazyLoadImage from "@/layouts/LazyLoadImage/LazyLoadImage"

const Card = ({ item }) => {
  return (
    <div className={ styles.root }>
      <Link href={ '/clubs/' + item.type + 's/[' + item.type + ']' } as={ '/clubs/' + item.type + 's/' + item.slug }>
        <a>
          <LazyLoadImage img={ <img src={ item.img_url } alt={ item.title }/> }/>
          <div className={ styles.container }>
            <div className={ styles.title + ' title-h3' }>{ item.title }</div>
            <div className={ styles.description }>{ item.count_str }</div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default Card
