import styles from './Stores.module.scss'
import BtnStore from "@/gdeluLib/BtnStore/BtnStore"
import { connect } from 'react-redux'

const Stores = ({ appLinks }) => {
  return (
    <div className={ styles.root }>
      <BtnStore btnName={ 'g-play' } links={ { android: appLinks.android } }/>
      <BtnStore btnName={ 'ios' } links={ { ios: appLinks.ios } }/>
      <BtnStore btnName={ 'app-gallery' } links={ { 'app-gallery': appLinks.appgallery } }/>
    </div>
  )
}

const mapStateToProps = state => ({
  appLinks: state.global.contacts.app_links
})

export default connect(mapStateToProps)(Stores)
