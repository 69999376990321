import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Img from './maps.webp'
import Img2 from './3.webp'
import Img3 from './2.webp'
import styles from './Advantages.module.scss'
import BtnStore from "@/gdeluLib/BtnStore/BtnStore"
import { throttle } from "throttle-debounce"
import LazyLoadImage from "@/layouts/LazyLoadImage/LazyLoadImage"
import Stores from "@/components/Home/Advantages/Stores/Stores";

const Advantages = ({ windowInnerWidth, appLinks, sizes, disableSwipe = windowInnerWidth > sizes.mob }) => {

  const touchOptionsModel = {
    startX: null,
    endTouchX: null,
    endTouchXNegative: null,
    currentStartedX: null
  }

  const [ activeElem, setActiveElem ] = useState(1)
  const [ timer, setTimer ] = useState(null)
  const [ touchOptions, setTouchOptions ] = useState(JSON.parse(JSON.stringify(touchOptionsModel)))

  useEffect(() => {
    startChangeContent()
    return () => {
      clearTimeout(timer)
      setTimer(null)
      setTouchOptions(JSON.parse(JSON.stringify(touchOptionsModel)))
      setActiveElem(1)
    }
  }, [])

  const startChangeContent = () => {
    let _timer = setTimeout(() => {
      setActiveElem(previousCount => {
        return +previousCount >= 3 ? 1 : +previousCount + 1
      })
      startChangeContent()
    }, 5000)
    setTimer(_timer)
  }

  const listObj = {
    1: {
      title: 'Найди фитнес-клуб рядом ',
      description: 'Выбирай удобный сегодня клуб. В будний день - рядом с офисом, в выходной - около дома.',
      img: Img,
    },
    2: {
      title: 'Забронируй и оплати онлайн',
      description: 'Тренажерный зал, бассейн, кроссфит, йога и многое другое, пробуй новое хоть каждый день. Тарифы от 150 рублей за тренировку с оплатой прямо в приложении.',
      img: Img3,
    },
    3: {
      title: 'Отправляйся на тренировку ',
      description: 'Покажи код администратору клуба и вперед на тренировку!',
      img: Img2,
    }
  }

  const touchStartHandler = (event) => {
    const touch = event.touches[ 0 ]
    const startX = touch.pageX
    // const elementWidth = ((wrapElement.offsetWidth - ((showElements - 1) * (marginRight || 0))) / showElements)
    const elementWidth = windowInnerWidth - 150
    const endTouchX = (elementWidth * 30 / 100) + startX
    const endTouchXNegative = startX - (elementWidth * 30 / 100)

    setTouchOptions({
      startX,
      endTouchX,
      endTouchXNegative
    })
  }

  const touchMoveHandler = (event) => {
    clearTimeout(timer)
    startChangeContent()
    const { startX, endTouchX, endTouchXNegative } = touchOptions
    const touch = event.touches[ 0 ].pageX

    if (startX === null || touch === startX) return
    else {
      if (touch <= startX) {
        if (touch <= endTouchXNegative) {
          setActiveElem(+activeElem >= 3 ? 1 : +activeElem + 1)
          touchEndHandler()
        }
        else {
          setTouchOptions({
            ...touchOptions,
            currentStartedX: (startX - touch) * -1
          })
        }
      }
      else {
        if (touch >= endTouchX) {
          setActiveElem(+activeElem <= 1 ? 3 : +activeElem - 1)
          touchEndHandler()
        }
        else {

          setTouchOptions({
            ...touchOptions,
            currentStartedX: touch - startX
          })
        }
      }
    }
    //console.log(event.touches, touchOptions)
  }

  const touchEndHandler = () => {
    setTouchOptions(JSON.parse(JSON.stringify(touchOptionsModel)))
  }

  const clickHandler = (key) => {
    setActiveElem(key)
    clearTimeout(timer)
    startChangeContent()
  }

  return (
    <div className={ styles.root + ' margin-v-1' }>
      <h2 className={ styles.title + ' title-h1 title-h1-m text-center' }><span>UPass </span>- фитнес без абонемента</h2>
      <div className={ 'text-2-m text-center' }>
        Это новый способ посетить лучшие тренажерные залы Москвы, чтобы вы могли сделать фитнес частью своего дня.
      </div>
      <div className={ styles.container }>
        <div className={ styles.shortContainer }>
          <div className={ styles.short }>
            { Object.keys(listObj).map((key, index) => {
              const item = listObj[ key ]
              return (
                <div className={ styles.item + (+key === +activeElem ? ' ' + styles.active : '') }
                     key={ index }
                     onClick={ clickHandler.bind(null, key) }>
                  <div className={ styles.title + ' title-h3' }>{ item.title }</div>
                  <div className={ styles.description }>{ item.description }</div>
                </div>
              )
            }) }
          </div>
          <div className={ styles.media }>
            <Stores/>
          </div>
        </div>
        { windowInnerWidth <= 730 ?
          <div className={ styles.toggles }>
            { Object.keys(listObj).map((key, index) =>
              <div className={ styles.toggle + (+key === +activeElem ? ' ' + styles.toggleActive : '') }
                   key={ index }
                   onClick={ clickHandler.bind(null, key) }/>
            ) }
          </div> : null }
        <div className={ styles.picked }
             onTouchStart={ disableSwipe ? null : touchStartHandler }
             onTouchMove={ disableSwipe ? null : event => throttle(25, touchMoveHandler(event)) }
             onTouchEnd={ disableSwipe ? null : touchEndHandler }>
          <div className={ styles.pickedTitle }>
          </div>
          <LazyLoadImage img={ <img src={ listObj[ activeElem ].img } alt={ listObj[ activeElem ].title }/> }/>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  windowInnerWidth: state.window.size.windowInnerWidth,
  sizes: state.window.sizes,
  appLinks: state.global.contacts.app_links
})

export default connect(mapStateToProps)(Advantages)
