import { connect } from 'react-redux'
import StarGradation from "./StarGradation/StarGradation"
import styles from './WhatUsersThink.module.scss'

const WhatUsersThink = ({ rating_max, rating }) => {
  return (
    <div className={ styles.root }>
      <div className={ styles.title + ' title-h1' }>
        Что думают о нас
      </div>
      <div className={ styles.stars }>
        <StarGradation totalStars={ rating }/>
      </div>
      <div className={ styles.maxRate }>
        { rating } / { rating_max }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  rating_max: state.global.rating_max,
  rating: state.global.rating,
})

export default connect(mapStateToProps)(WhatUsersThink)
