import React, { useEffect } from 'react'
import styles from './Slot.module.scss'
import PropTypes from 'prop-types'

const Slot = ({ item, index, answer, seoSlotTitle, seoSlotDescription, answerKey, isProviderFaq }) => {
  console.log(item.body)
  const ref = React.createRef()

  useEffect(() => {
    setHeight(ref)
  }, [ ref ])

  const setHeight = ({ current }) => {
    if (!current) return
    else {
      const rootPadding = sum(getStyle(current, [ 'paddingTop', 'paddingBottom' ]))
      const { childNodes } = current
      if (childNodes.length) {
        const titleHeight = sum(getStyle(childNodes[ 0 ], [ 'height' ]))
        let totalHeight = item.isActive ? titleHeight + sum(getStyle(childNodes[ 1 ], [ 'height', 'marginTop' ])) : titleHeight
        totalHeight += rootPadding
        current.style.maxHeight = totalHeight + 'px'
      }
    }
  }

  const sum = arr => arr.reduce((acc, item) => acc += item, 0)

  const getStyle = (node, styleList) => styleList.map(style => Number(getComputedStyle(node)[ style ].replace('px', '')))

  return (
    <div className={ styles.root + (item.isActive ? ' ' + styles.isActive : '') }
         custom-id={ index }
         ref={ ref }>
      <div className={ styles.titleContainer }>
        <div className={ styles.title + ' title-h3' } { ...seoSlotTitle }>{ !isProviderFaq ? item.title : item.question }</div>
        { !item.isActive ?
          <svg width="36" height="4" viewBox="0 0 36 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M35.3409 0.607875C35.0303 0.353105 34.633 0.200195 34.2 0.200195H1.8C0.805888 0.200195 0 1.00608 0 2.0002C0 2.5536 0.249744 3.04868 0.642676 3.37887C0.955611 3.64192 1.3594 3.80036 1.80019 3.80036L34.2002 3.80036C35.1943 3.80036 36.0002 2.99447 36.0002 2.00036C36.0002 1.43918 35.7434 0.937985 35.3409 0.607875Z"
                  fill="#333333"/>
          </svg> :
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18.0008 36C18.9949 36 19.8008 35.1941 19.8008 34.2L19.8008 19.8001H34.2C35.1941 19.8001 36 18.9942 36 18.0001C36 17.006 35.1941 16.2001 34.2 16.2001H19.8008L19.8008 1.8C19.8008 0.805888 18.9949 0 18.0008 0C17.0067 0 16.2008 0.805888 16.2008 1.8V16.2001H1.8C0.805888 16.2001 0 17.006 0 18.0001C0 18.9942 0.805888 19.8001 1.8 19.8001H16.2008V34.2C16.2008 35.1941 17.0067 36 18.0008 36Z"
                  fill="#333333"/>
          </svg> }


      </div>
      <div
        className={ styles.description } { ...seoSlotDescription }>{ isProviderFaq ? <div
        dangerouslySetInnerHTML={ { __html: item.answer } }/> : (!!answer ? answer[ item[ answerKey ] ] : item.body) }</div>
    </div>
  )
}

Slot.propTypes = {
  item: PropTypes.object,
}

export default Slot
