import styles from './Card.module.scss'
import LazyLoadImage from "@/layouts/LazyLoadImage/LazyLoadImage"

const Card = ({ item }) => {
  return (
    <div className={ styles.root }>
      <a href={ item.href } target={ '_blank' } rel='noreferrer noopener'>
        <LazyLoadImage img={ <img src={ item.img_url } alt={ item.title }/> }/>
        <div className={ styles.container }>
          <div className={ styles.title + ' title-h3' }>{ item.date_str }</div>
          <div className={ styles.description }>{ item.title }</div>
        </div>
      </a>
    </div>
  )
}

export default Card
