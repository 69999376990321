import { connect } from 'react-redux'
import { useEffect, useState, memo } from 'react'
import Link from "next/link"
import styles from './ClubsLine.module.scss'

const ClubsLine = memo(({ clubs, windowInnerWidth }) => {
  const [ tryShowWidth, setTryShowWidth ] = useState(0)
  const [ reminderWidth, setReminderWidth ] = useState(0)
  let timer

  useEffect(() => {
    startLineAnimation()
    return () => {
      clearTimeout(timer)
    }
  }, [ clubs, windowInnerWidth ])


  const startLineAnimation = () => {
    if (timer) clearInterval(timer)
    const root = document.querySelector('.' + styles.root)
    const container = document.querySelector('.' + styles.container)
    const rootWidth = root.offsetWidth
    const containerWidth = container.offsetWidth
    if (rootWidth < containerWidth) {
      setTryShowWidth(0)
      setReminderWidth(containerWidth - rootWidth)
      console.log(containerWidth - rootWidth, 'startLineAnimation')
      // const reminderWidth = containerWidth - rootWidth
      // console.log(reminderWidth)
      // const totalTime = 5000
      // let side = -1
      // const totalWidth = (totalTime / reminderWidth)
      // let currentPosition = -reminderWidth
      // let start = Date.now()
      //
      // const timerAnimation = () => {
      //
      //   timer = setInterval(function () {
      //     let timePassed = Date.now() - start
      //
      //     if (timePassed >= totalTime) {
      //       side *= -1
      //       clearInterval(timer)
      //       setTimeout(() => {
      //         start = Date.now()
      //         timerAnimation()
      //       }, 1000)
      //       return
      //     }
      //
      //     draw(timePassed)
      //
      //   }, 10)
      //   setTimerG(timer)
      // }
      // timerAnimation()
      //
      // function draw(timePassed) {
      //   const left = side === 1
      //     ? +currentPosition + (timePassed / totalWidth)
      //     : (timePassed / totalWidth > reminderWidth ? reminderWidth : timePassed / totalWidth) * side
      //   container.style.left = left + 'px'
      // }
    }
    else {
      setReminderWidth(0)
      if (tryShowWidth < 4) {
        setTryShowWidth(tryShowWidth + 1)
        timer = setTimeout(() => startLineAnimation(), 500)
      }
    }
  }
  console.log({ reminderWidth }, '1')

  return (
    <>
      { reminderWidth > 0 ?
        <style jsx="true">{ `
          @keyframes animation-left {
            0% {
              transform: translateX(0);
            }
            50% {
              transform: translateX(-${ reminderWidth }px);
            }
            100% {
              transform: translateX(0);
            }
          }

          .horizontal-animation-left-line {
            animation: ${ reminderWidth / (reminderWidth <= 4 ? 0.5 : reminderWidth <= 20 ? 2 : 10) }s linear .5s infinite normal animation-left;
          }
        ` }</style> : null }
      <div className={ styles.root + ' margin-v-1' }>
        <div className={ styles.container + ' horizontal-animation-left-line' }>
          { clubs.map((item) =>
            <Link as={ '/club/' + item.slug } href={ '/club/[slug]' } key={ item.slug }>
              <a className={ styles.item }>
                <img src={ item.logo } alt="" height={ 52 }/>
              </a>
            </Link>
          ) }
        </div>
      </div>
    </>
  )
})

const mapStateToProps = state => ({
  clubs: state.popular.club.list,
  windowInnerWidth: state.window.size.windowInnerWidth,
})

export default connect(mapStateToProps)(ClubsLine)
