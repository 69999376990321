import { connect } from "react-redux"
import Card from "./Card/Card"
import SlideShow from "@/gdeluLib/SlideShow/SlideShow"
import styles from './PopularDestination.module.scss'

const PopularDestination = ({ destination, windowInnerWidth }) => {
  return (
    <div className={ styles.root + ' margin-v-1' }>
      <h2 className={ 'title title-h1-m text-center' }>Популярные направления</h2>
      <div className={ 'text-2-m  text-center' }>Выбирай свое направление в более 300 фитнес клубах</div>
      <SlideShow
        slideHeight={ windowInnerWidth <= 750 ? 320 : windowInnerWidth <= 900 ? 203 : windowInnerWidth <= 1250 ? 250 : 303 }
        marginRight={ windowInnerWidth <= 700 ? 0 :20 } classNameContainer={ 'popularDestination' }>
        { destination.map((item, index) =>
          <Card key={ index } item={ item }/>
        ) }
      </SlideShow>
    </div>
  )
}

const mapStateToProps = state => ({
  destination: state.popular.destination.list,
  windowInnerWidth: state.window.size.windowInnerWidth,
})

export default connect(mapStateToProps)(PopularDestination)
