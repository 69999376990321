import styles from './Header.module.scss'
import Btn from "@/gdeluLib/Btn/Btn"
import { connect } from "react-redux"
import { openModal, setModalAdditional } from "@/store/modal/actions"
import styleDownload from '@/components/Modal/DownloadMobApp/DownloadMobApp.module.scss'

const Header = ({ mobileOs, sizes, windowInnerWidth, setModalAdditional, openModal, modalNames, appLinks }) => {

  const clickHandler = () => {
    setModalAdditional({ className: styleDownload.modal })
    openModal(modalNames.downloadMobApp)
  }

  return (
    <div className={ styles.root + ' margin-v-2' }>
      <h1 className={ styles.title + ' title-h1-m' }>Твой пропуск
        <div>в фитнес-клубы Москвы</div>
      </h1>
      <div className={ 'second-title text-2-m ' + styles.secondTitle }>
        Установи приложение и тренируйся без покупки абонемента!
      </div>
      <div className={ styles.btns }>
        { windowInnerWidth <= sizes.mob ? <div className={ styles.downloadBtn }>
            <Btn link={ mobileOs === 'android' ? appLinks.android : mobileOs === 'iso' ? appLinks.ios : appLinks.android }
                 isNativeLink={ true } title={ 'Установить' } styleBtn={ 'blue' }/>
          </div> :
          <div className={ styles.downloadBtn }>
            <Btn onClick={ clickHandler } title={ 'Установить' } styleBtn={ 'blue' }/>
          </div> }
        <Btn link={ '/clubs/' } href={ '/clubs/' } title={ 'Выбрать клуб' } styleBtn={ 'darkTransparent' }/>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  mobileOs: state.global.mobileOs,
  modalNames: state.modal.modalNames,
  windowInnerWidth: state.window.size.windowInnerWidth,
  sizes: state.window.sizes,
  appLinks: state.global.contacts.app_links
})

const mapDispatchToProps = dispatch => ({
  setModalAdditional: data => dispatch(setModalAdditional(data)),
  openModal: name => dispatch(openModal(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
