import { useState, useEffect } from 'react'
import { withRouter } from "next/router"
import { connect } from 'react-redux'
import { searchClubs } from "@/store/clubs/filters/search/actions"
import styles from './PopularClubs.module.scss'
import SlideShow from "@/gdeluLib/SlideShow/SlideShow"
import Btn from "@/gdeluLib/Btn/Btn"
import Card from "./Card/Card"
import SearchInputField from "@/gdeluLib/SearchInputField/SearchInputField"

const PopularClubs = ({ clubs, router, windowInnerWidth, searchClubs, findClubs }) => {
  const [ search, setSearch ] = useState('')

  const searchHandler = (value) => {
    if (search) searchClubs(search, true)
    router.push('/clubs')
  }

  useEffect(() => {
    setTimeout(() => searchClubs(''), 1500)
  }, [])


  const changeSearchValueHandler = value => {
    setSearch(value)
    searchClubs(value)
  }

  return (
    <div className={ styles.root + ' margin-v-1' }>
      <h2 className={ 'title-h1 text-center' }>Популярные фитнес клубы</h2>
      <div className={ 'text-2-m text-center' }>Выбери свой клуб из более 300 фитнес-клубов</div>
      <div className={ styles.search }>
        <SearchInputField dropDownList={ Object.keys(findClubs).map(key => ({ ...findClubs[ key ] })) }
                          placeholder={ 'Поиск клуба' } itemKey={ 'title' }
                          secondTitle={ 'address' }
                          filtered={ true }
                          emptyMessage={ 'Клуб не найден' }
                          value={ search }
                          changeSearch={ ({ item }) => router.push('/club/[slug]', '/club/' + item.id) }
                          changeSearchValue={ changeSearchValueHandler }
                          inputClassName={ styles.input }/>
        <Btn title={ 'Поиск' } styleBtn={ 'blue' } className={ styles.searchBtn } onClick={ searchHandler }/>
      </div>
      <SlideShow showElements={ windowInnerWidth <= 700 ? 1 : windowInnerWidth <= 1100 ? 2 : 3 }
                 marginRight={ windowInnerWidth <= 700 ? 0 : 20 } slideHeight={ 303 }
                 classNameContainer={ 'IndexPopularClubs' }>
        { clubs.map((item, index) =>
          <Card item={ item } key={ item.slug }/>
        ) }
      </SlideShow>
      <Btn title={ 'Показать все клубы' } className={ styles.btn } href={ '/clubs' } link={ '/clubs' }
           styleBtn={ 'darkTransparent' }/>
    </div>
  )
}

//refact
const mapStateToProps = state => ({
  clubs: state.popular.club.list,
  windowInnerWidth: state.window.size.windowInnerWidth,
  findClubs: state.clubs.filters.search.clubs,
  search: state.clubs.filters.search.value,
})
//searchClubs
const mapDispatchToProps = dispatch => ({
  searchClubs: (value, dontSearch) => dispatch(searchClubs(value, dontSearch))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopularClubs))
