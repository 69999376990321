import { connect } from 'react-redux'
import { useState } from 'react'
import Opinion from "./Opinion/Opinion"
import styles from './OpinionsList.module.scss'

const OpinionsList = ({ comment, windowInnerWidth, sizes }) => {
  const [ currentPage, setCurrentPage ] = useState(1)
  const left = windowInnerWidth <= 360 ? 300 : windowInnerWidth <= sizes.mob ? 340 : windowInnerWidth <= sizes.tab ? 557 : windowInnerWidth <= sizes.smallD ? 666 : 757

  return (
    <div className={ styles.root }>
      <div className={ styles.container }>
        <div className={ styles.opinions } style={ { left: -1 * ((currentPage - 1) * left) + 'px' } }>
          { comment.map((item, index) =>
            <Opinion item={ item } key={ index } className={ currentPage !== index + 1 ? styles.opinion : undefined }/>
          ) }
        </div>
        <div className={ styles.pages }>
          { comment.map((item, index) =>
            <div
              key={ index }
              onClick={ setCurrentPage.bind(null, index + 1) }
              className={ styles.page + (index + 1 === currentPage ? ' ' + styles.activePage : '') }>
              { (index + 1).toString().length < 2 ? 0 : null }{ index + 1 }
            </div>
          ) }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  comment: state.popular.comment.list,
  windowInnerWidth: state.window.size.windowInnerWidth,
  sizes: state.window.sizes,
})

export default connect(mapStateToProps)(OpinionsList)
