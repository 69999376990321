import { connect } from "react-redux"
import SlideShow from "@/gdeluLib/SlideShow/SlideShow"
import Card from "./Card/Card"

const Blog = ({ blog, windowInnerWidth }) => {
  return (
    <div className={ 'margin-v-1' }>
      <h2 className={ 'title-h1 text-center margin-v-2' }>Блог о фитнесе</h2>
      <SlideShow
        slideHeight={ windowInnerWidth <= 750 ? 320 : windowInnerWidth <= 900 ? 203 : windowInnerWidth <= 1250 ? 250 : 303 }
        marginRight={ windowInnerWidth <= 700 ? 0 : 20 } classNameContainer={ 'blog' }>
        { blog.map((item, index) =>
          <Card key={ index } item={ item }/>
        ) }
      </SlideShow>
    </div>
  )
}

const mapStateToProps = state => ({
  blog: state.blog.list,
  windowInnerWidth: state.window.size.windowInnerWidth,
})

export default connect(mapStateToProps)(Blog)
