import { Fragment } from 'react'
import StarGradation from "../../WhatUsersThink/StarGradation/StarGradation"
import styles from './Opinion.module.scss'
import LazyLoadImage from "@/layouts/LazyLoadImage/LazyLoadImage";

const Opinion = ({ item, className }) => {
  return (
    <div className={ styles.root + (className ? ' ' + className : '') }>
      <LazyLoadImage img={ <img src={ item.avatar } alt=""/> }/>
      <div className={ styles.container }>
        <div className={ styles.fullname + ' title-h3' }>{ item.author }</div>
        <div className={ styles.stars }>
          <StarGradation totalStars={ item.rating }/>
        </div>
        <div className={ styles.text }>
          { item.text ? <Fragment>
            { item.text.length > 232 ? item.text.slice(229) + '...' : item.text }
          </Fragment> : null }
        </div>
      </div>
    </div>
  )
}

export default Opinion
