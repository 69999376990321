import { getPopular } from "@/store/popular/actions"
import { setPopularClubs } from "@/store/popular/club/actions"
import { setPopularDestinations } from "@/store/popular/destination/actions"
import { setPopularComments } from "@/store/popular/comment/actions"
import { getBlog } from "@/store/blog/actions"
import { setBlogList } from "@/store/blog/actions"
import { getFaq, setFaqList } from "@/store/faq/actions"
import Header from '@/components/Home/Header/Header'
import PopularDestination from "@/components/Home/PopularDestination/PopularDestination"
import Blog from "@/components/Home/Blog/Blog"
import SubscriptionAndCon from "@/components/Home/SubscriptionAndCon/SubscriptionAndCon"
import ClubsLine from "@/components/Home/ClubsLine/ClubsLine"
import Advantages from "@/components/Home/Advantages/Advantages"
import PopularClubs from "@/components/Home/PopularClubs/PopularClubs"
import FAQ from "@/components/Home/FAQ/FAQ"
import Opinions from "@/components/Home/Opinions/Opinions"
import { useEffect } from "react"

const Home = () => {
  useEffect(() => {
    return () => {
      console.log('home unmount')
    }
  }, [])
  return (
    <div>
      <Header/>
      <ClubsLine/>
      <section id='home-advantages'>
        <Advantages/>
      </section>
      <section id='popular-destination'>
        <PopularDestination/>
      </section>
      <SubscriptionAndCon/>
      <PopularClubs/>
      <section id='home-opinions'>
        <Opinions/>
      </section>
      <section id='home-faq'>
        <FAQ/>
      </section>
      <Blog/>
    </div>
  )
}

Home.getInitialProps = async ctx => {
  let { store, req } = ctx
  const isServer = !!req
  let { dispatch } = store
  const allAsyncMethod = () => {
    return Promise.all([
      dispatch(getPopular({ set: setPopularClubs, get: 'getClubs' })),
      dispatch(getPopular({ set: setPopularDestinations, get: 'getDestinations' })),
      dispatch(getPopular({ set: setPopularComments, get: 'getComments' })),
      dispatch(getBlog({ set: setBlogList, get: 'getBlog' })),
      dispatch(getFaq({ set: setFaqList, get: 'getFaq' })),
    ])
  }
  if (isServer) await allAsyncMethod()
  else allAsyncMethod()
  return {}
}

export default Home
