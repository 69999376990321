import React, { Fragment, memo, useEffect, useState } from 'react'
import preloadImg from './preloader.png'
import styles from './LazyLoadImage.module.scss'
import { connect } from 'react-redux'

const LazyLoadImage = memo(({ img, windowScrollTop }) => {
  const [ showImage, setShowImage ] = useState(false)
  const loaderRef = React.createRef()

  useEffect(() => {
    if (!showImage) {

      if (!!loaderRef.current) {
        const loaderPosition = loaderRef.current.getBoundingClientRect().top
        if (!!loaderPosition && loaderPosition - (window.innerHeight / 2) <= window.innerHeight) {
          setShowImage(true)
        }
      }
    }
  }, [ windowScrollTop, img ])

  return (
    typeof window === 'undefined' ?
      <>

        { img.props.src ?
          <img className={ styles.img + (img.props && img.props.className ? ' ' + img.props.className : '') }
               data-src={ img.props.src } alt={ img.props.alt }/> : null }
      </>
      :
      img.props.src ?
        <img
          src={ showImage ? img.props.src : undefined }
          ref={ loaderRef }
          onClick={ img.props.onClick || null }
          className={ (!showImage ? styles.img : '') + (img.props && img.props.className ? ' ' + img.props.className : '') }
          data-src={ img.props.src } alt={ img.props.alt }/> : img
  )
})

const mapStateToProps = state => ({
  windowScrollTop: state.window.scroll.pageYOffset
})

export default connect(mapStateToProps)(LazyLoadImage)
