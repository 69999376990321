import styles from './Opinions.module.scss'
import WhatUsersThink from "./WhatUsersThink/WhatUsersThink"
import OpinionsList from "./OpinionsList/OpinionsList"

const Opinions = () => {
  return (
    <div className={ styles.root + ' margin-v-1' }>
      <div className={ styles.container }>
        <WhatUsersThink/>
        <OpinionsList/>
      </div>
    </div>
  )
}

export default Opinions
